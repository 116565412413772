<template>
	<div class="newEmailWindowContainer shadow" :style="{width: isFullscreen ? '100%' : (mobile ? 'calc(100% + 50px)' : (opened ? '650px' : '300px'))}" :class="{mobile: mobile, fullscreen: isFullscreen}">
		<div class="header" @click="mobile ? null : opened = !opened">
			<icon class="legend">drafts</icon>
			<h5>{{ formValue?.subject ? formValue.subject : $t('mailermail.newmessage') }}</h5>
			<icon class="bl-icon-button" v-if="controls.fullscreen && opened" @click="toggleFullscreen($event)">{{ isFullscreen ? 'fullscreen_exit' : 'fullscreen' }}</icon>
			<icon class="bl-icon-button" @click="close($event)">close</icon>
		</div>
		<div class="body" :style="{display: opened || isFullscreen ? null : 'none'}">
			<BlForm name="mailermail" @modelValue="formChange($event)" :saveButtonLabel="$t('mailermail.send')" @submitted="controls.close(true)" :context="{contacts: contacts, subject: subject, body: body, attachments: attachments, duplicateId: duplicateId, object: object}" url="mailermail" :disableDialogLeave="true" ref="form">
				<hr style="margin-top: 0;" />
				<div class="recipientContainer fromContainer" :style="{display: showFromSelector()}">
					<span class="legend" style="margin-top: -6px;">{{ $t('mailermail.property.from') }}</span>
					<div style="flex: 1"><BlFormField name="from" /></div>
				</div>
				<hr style="margin-top: 2px;" :style="{display: showFromSelector()}" />
				<div class="recipientContainer">
					<span class="legend">{{ $t('mailermail.property.to') }}</span>
					<div style="flex: 1"><BlFormField name="to" /></div>
					<span class="link" @click="showCC = true" v-if="!showCC">{{ $t('mailermail.property.cc') }}</span>
					<span class="link" @click="showBCC = true" v-if="!showBCC">{{ $t('mailermail.property.bcc') }}</span>
				</div>
				<div :style="{display: showCC ? 'flex' : 'none'}" class="recipientContainer" style="margin-top: 8px;">
					<span class="legend">{{ $t('mailermail.property.cc') }}</span>
					<div style="flex: 1"><BlFormField name="cc" /></div>
				</div>
				<div :style="{display: showBCC ? 'flex' : 'none'}" class="recipientContainer" style="margin-top: 8px;">
					<span class="legend">{{ $t('mailermail.property.bcc') }}</span>
					<div style="flex: 1"><BlFormField name="bcc" /></div>
				</div>
				<hr style="margin-top: 6px;" />
				<div class="subjectContainer"><BlFormField name="subject" /></div>
				<hr style="margin-bottom: -5px;" />
				<div style="flex: 1" class="bodyContainer">
					<BlFormField name="body" />
				</div>
				<hr style="margin-top: -10px;" />
				<div style="margin-top: 8px;">
					<BlFormField name="attachments" />
				</div>
				<hr style="margin: 11px 0 0 5px;" />
				<div class="bottomActions">
					<div style="display: none;"><BlFormField name="trackingEnabled" /></div>
					<div class="tracking" :class="{active: $refs.form.form.getChild('trackingEnabled').value}" @click="$refs.form.form.getChild('trackingEnabled').setValue(!$refs.form.form.getChild('trackingEnabled').value)" v-bl-tooltip="$t('mailermail.track')">
						<button type="button" class="bl-icon-button">track_changes</button>
						<span>{{ $t('mailermail.track') }}</span>
					</div>
					<div style="flex: 1;"></div>
					<BlFormSubmit />
				</div>
			</BlForm>
		</div>
	</div>
</template>

<script>
import { Variables, Dialog } from 'InterfaceBundle'

export default {
	name: 'BlMailWindow',
	props: ['controls', 'contacts', 'subject', 'body', 'attachments', 'duplicateId', 'object'],
	data() {
		return {
			opened: true,
			mobile: Variables.mobile,
			formValue: null,
			showCC: false,
			showBCC: false,
			isFullscreen: false
		}
	},
	methods: {
		formChange(formValue) {
			this.formValue = formValue
			if(formValue.cc) this.showCC = true
			if(formValue.bcc) this.showBCC = true
		},
		close(ev) {
			ev.stopPropagation()
			Dialog.confirm({
				title: this.$t('mailermail.discardDialog.title'),
				accept: this.$t('mailermail.discardDialog.accept'),
				cancel: this.$t('mailermail.discardDialog.cancel')
			}).then(() => {
				this.controls.close(false)
			})
		},
		toggleFullscreen($event) {
			$event.stopPropagation()
			if(this.isFullscreen) this.controls.smallscreen()
			else this.controls.fullscreen()
			this.isFullscreen = !this.isFullscreen
		},
		showFromSelector() {
			return this.$refs.form?.form && Object.keys(this.$refs.form.form.getChild('from').options.choices).length > 1 ? null : 'none'
		}
	}
}
</script>

<style scoped lang="scss">
	.newEmailWindowContainer {
		display: flex;
		flex-direction: column;
		border: 1px solid var(--bl-border);
		border-bottom: 0;
		border-top-right-radius: var(--bl-border-radius);
		border-top-left-radius: var(--bl-border-radius);
		background-color: var(--bl-surface);

		.header {
			display: flex;
			align-items: center;
			padding: 2px 0 2px 10px;
			cursor: pointer;

			icon.legend {
				color: var(--bl-legend);
				font-size: 16px;
				margin-right: 6px;
			}

			h5 {
				font-family: 'Product sans';
				font-size: 14px;
				flex: 1;
				margin: 0;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				font-weight: normal;
			}

			icon.bl-icon-button {
				font-size: 20px;
				padding: 7px;
			}
		}

		.body {
			flex: 1;
			min-height: 600px;
			display: flex;
			flex-direction: column;

			> form::v-deep > div.bl-card:first-child {
				border: 0;
				border-radius: 0;
				margin: 0;
			}

			hr {
				width: calc(100% - 10px);
			}
		}

		//Custom form styling
		.body::v-deep form {
			display: flex;
			flex: 1;
			flex-direction: column;
		}

		.body::v-deep :is(.bl-input, .bl-input-multiple) {
			margin-bottom: -15px;
			margin-top: -10px;

			input, .bl-input-multiple-content {
				box-shadow: none !important;
			}
		}

		.body::v-deep :is(.bl-input:focus-within, .bl-input__value) label {
			display: none;
		}

		.body::v-deep .blFormFieldRichText {
			.inputContainer {
				box-shadow: none;
				border-radius: 0;
			}

			label {
				display: none;
			}
		}

		.body::v-deep .contentEditableContainer {
			min-height: 400px;
		}

		.body::v-deep form button[type="submit"] {
			float: left;
			margin: 10px;
		}

		.recipientContainer {
			display: flex;
			align-items: center;
			padding: 2px 0;
			min-height: 24px;

			.legend {
				color: var(--bl-legend);
				width: 35px;
				padding-left: 8px;
				display: block;
			}

			.link {
				color: var(--bl-legend);
				cursor: pointer;
				padding: 0 4px;
			}

			.link:hover {
				text-decoration: underline;
			}

			:deep .vue-recycle-scroller {
				margin-left: -1px;
				width: calc(100% - 6px);
				border: 1px solid var(--bl-border);
				margin-top: 14px;
				margin-right: 5px;
			}
		}

		.bodyContainer::v-deep > div {
			height: calc(100% - 10px);

			.contentEditableContainer {
				max-height: 400px;
			}
		}

		.bodyContainer::v-deep :is(.blFormFieldRichText, .wysiwyg, .contentEditableContainer) {
			height: 100%;
		}

		.recipientContainer.fromContainer::v-deep .bl-input {
			margin: -10px 0 -10px 0;

			* {
				cursor: pointer;
			}
		}

		.subjectContainer {
			margin: 5px 0 3px 0;
		}

		.subjectContainer::v-deep input {
			font-weight: 500;
		}
	}

	.newEmailWindowContainer.mobile {
		margin-top: -25px;
		margin-left: -25px;
		border: 0;
		height: calc(100% + 50px);

		.body {
			min-height: 0;
			height: 100%;
		}
	}

	.fullscreen {
		height: 100%;
		border: 1px solid var(--bl-border);
		border-radius: var(--bl-border-radius);

		.body {
			min-height: 0;
			height: 100%;
		}
	}

	.bottomActions {
		display: flex;
		padding-left: 5px;
		align-items: center;

		> div.tracking {
			display: flex;
			transition: all .2s;
			align-items: center;
			cursor: pointer;
			border-top-left-radius: 14px;
			border-bottom-left-radius: 14px;
			border-top-right-radius: var(--bl-border-radius);
			border-bottom-right-radius: var(--bl-border-radius);

			span {
				display: none;
				font-size: 12px;
				transform-origin: 0 0;
				color: var(--bl-legend);
			}

			button {
				padding: 4px;
			}

			.bl-icon-button {
				margin: -5px 0 -5px -3px;
			}
		}

		> div.tracking.active {
			background-color: color-mix(in srgb, var(--bl-surface) 80%, var(--bl-secondary));
			padding-right: 6px;

			span {
				animation: trackingPopIn 0.1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
				display: block;
			}

			.bl-icon-button {
				color: var(--bl-secondary);
			}
		}
	}

	@keyframes trackingPopIn {
		0% {
			transform: scaleX(0);
		}
		100% {
			transform: scaleX(1);
		}
	}
/*
	.bottomActions .bl-icon-button.active {
		background-color: var(--bl-secondary);
		color: var(--bl-on-secondary);
	}
*/
</style>